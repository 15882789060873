setTimeout(() => {
  const controllerButton = [].slice.call(document.getElementsByClassName('controllerButton'));
  const tabs = [].slice.call(document.getElementsByClassName('taball'))
  const tabLinks = [].slice.call(document.getElementsByClassName('tablinks'))
  const planos = [].slice.call(document.getElementsByClassName('planos'))
  const plansDetails = [].slice.call(document.getElementsByClassName('plansContainer'))

  // Função ativa os detalhes dos planos
  const exibeDetalhesPlanos = (infoTab) => {
    if(plansDetails.length){
      plansDetails.forEach(planDetail => {
        if(infoTab == planDetail.dataset?.plan){
          planDetail.style.display = 'flex';
        }
      })
    }
  }

  // O controllerButton são os botões de internet e internet + streaming
  controllerButton.forEach((botao, index) => {
    botao.addEventListener('click', function () {
      controllerButton.forEach((item) => {
        item.classList.remove('active')
      })
      if (botao == this) {
        botao.classList.add('active')
      }
      tabLinks.forEach(item => {
        item.classList.remove('active')
      })
      tabs.forEach(item => item.classList.remove('active'))
      tabs[index].classList.add('active')
      planos.forEach(item => item.classList.remove('active'))
      plansDetails.forEach((planDetail) => planDetail.style.display = 'none')

      //Se o index é igual a 0 então deve mostrar o plano só com internet
      if (index == 0) {
        tabLinks[0].classList.add('active')
        planos[0].classList.add('active')
      } else {
        // Nesse caso mostra os planos com streaming
        tabLinks[1].classList.add('active')
        planos.forEach(item => {
          if (item?.dataset?.plan == tabLinks[1].dataset?.plan) {
            item.classList.add('active')
          }
        })
        if (tabLinks[1]?.dataset?.plan) {
          exibeDetalhesPlanos(tabLinks[1]?.dataset?.plan)
        }
      }
    })
  })

  tabLinks.forEach((tab, index) => {
    // Verifica se a tab link ativa inicialmente contem um detalhe de planos, se tiver ativa ele
    if(tab.classList.contains('active') && tab?.dataset?.plan){
      exibeDetalhesPlanos(tab?.dataset?.plan)
    }

    tab.addEventListener('click', function () {
      tabLinks.forEach(item => item.classList.remove('active'))
      this.classList.add('active')
      planos.forEach(item => item.classList.remove('active'))

      // Seleciona o plano correto
      if (index == 0) {
        planos[0].classList.add('active')
      } else {
        planos.forEach(item => {
          if (item?.dataset?.plan == tab?.dataset?.plan) {
            item.classList.add('active')
          }
        })
      }

      // Seleciona os detalhes de planos corretos
      plansDetails?.forEach((planDetail) => planDetail.style.display = 'none')
      if (tab?.dataset?.plan) {
        exibeDetalhesPlanos(tab?.dataset?.plan)
      }
    })
  })
}, 2000);
