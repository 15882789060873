window.addEventListener('load', async () => {
  const cpfField = document.getElementById('cpfField');
  const btnModal = document.querySelector('.button-modal');
  const modalContainer = document.querySelector('.modal-container');
  const form = document.querySelector('.form-lp');

  if(cpfField != null) {
    cpfField.addEventListener('input', function () {
      let cpf = cpfField.value;
      cpf = cpf.replace(/\D/g, '');
      cpf = cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      cpfField.value = cpf;
    });
  }

  if(btnModal != null) {
    btnModal.addEventListener('click', () => modalContainer.style.display = "none")
  }

  if(form != null) {
    form.addEventListener('submit', (event) => {
      event.preventDefault();
      modalContainer.style.display = "flex";
    });
  }
})
